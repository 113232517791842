export default [
  {
    id: 'internal',
    title: 'Internal Person'
  },
  {
    id: 'external',
    title: 'External Person'
  },
  {
    id: 'agency',
    title: 'Agency'
  },
  {
    id: 'shortname',
    title: 'Author Shortname'
  },
  {
    id: 'doctor',
    title: 'Doctor'
  },
  {
    id: 'gardener',
    title: 'Záhradkár'
  }
]
