<script>
import AuthorSearchSelect from '@/components/author/AuthorSearchSelect'
import CoreApi from '@/api/core'

export default {
  name: 'OtherFunctionSearchSelect',
  extends: AuthorSearchSelect,
  data () {
    return {
      title: this.$t('authorVlm.otherFunction')
    }
  },
  methods: {
    // overriding from AuthorSearchSelect.vue
    getItemName (item) {
      return item?.name?.trim() ?? ''
    },
    // overriding from AuthorSearchSelect.vue
    async fetchItems (query) {
      const filterQuery = query ? `&filter_contains[name]=${query}` : ''
      return (await CoreApi().get(`/authorOtherFunction?limit=${this.limit}${filterQuery}`)).data.data
    }
  }
}
</script>
