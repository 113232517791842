<script>
import AuthorNewViewVlm from '@/views/author/vlm/AuthorNewViewVlm'

export default {
  name: 'AuthorEditViewVlm',
  extends: AuthorNewViewVlm,
  data () {
    return {
      dataLoaded: false
    }
  },
  async created () {
    await this.getAuthor()
  }
}
</script>
