<template>
  <ModuleForm
    :module="author"
    :show-header-info="disabled"
  >
    <template #form-title>
      {{ $t(`route.${$route.name}`) }}
    </template>
    <template #header-buttons>
      <ModuleFormButton
        v-if="disabled"
        :required-permissions="requiredPermissions.editButton"
        icon="edit"
        @click="goToEdit"
      />
      <ModuleFormButton
        v-else
        :title="$t('buttons.save')"
        :disabled="loading"
        :required-permissions="requiredPermissions.saveButton"
        @click="save"
      />
      <ModuleFormDeleteButton
        v-if="author.id"
        :record-id="author.id"
        :disabled="loading"
        :required-permissions="requiredPermissions.deleteButton"
        store-name="author"
        list-router-path="author"
      />
      <ModuleFormButton
        icon="close"
        @click="$router.push('/author')"
      />
    </template>
    <template #form-content>
      <Preloader v-if="loading" on-top />
      <div v-if="dataLoaded" class="form-content">
        <div class="form-content__left">
          <div class="two-columns">
            <Input
              v-model="author.name"
              id="author_firstName"
              :label="$t('authorVlm.firstName')"
              :disabled="disabled"
            />
            <Input
              v-model="author.originName"
              @blur="$v.author.originName.$touch()"
              :error="$v.author.originName.$error"
              id="author_lastName"
              :label="$t('authorVlm.lastName')"
              :disabled="disabled"
              required
            />
          </div>
          <div class="two-columns">
            <SourceSearchSelect
              v-model="author.sourceName"
              @selected-item="sourceChanged"
              @blur="$v.author.source.$touch()"
              :error="$v.author.source.$error"
              :disabled="disabled"
              :select-title="`${$t('authorVlm.source')} *`"
              :allow-custom-items="false"
              :search-on-empty-query="true"
              :key="`author_source-${forceUpdateSource}`"
              id="author_source"
            />
            <div class="form-group">
              <div class="title-text title-text--multiselect">
                {{ $t('authorVlm.user') }}
              </div>
              <MultiSelect
                :value="author.user ? $store.getters['user/userById'](author.user) : null"
                @input="(authorObject) => author.user = authorObject ? authorObject.id: null"
                :options="users"
                :multiple="false"
                :close-on-select="true"
                label="username"
                track-by="id"
                id="author_user"
                :disabled="disabled"
              />
            </div>
          </div>
          <div class="two-columns">
            <Select
              v-model="author.defaultSite"
              @blur="$v.author.defaultSite.$touch()"
              @change="defaultSiteChanged"
              :error="$v.author.defaultSite.$error"
              :options="enabledSites"
              id="author_defaultSite"
              :label="$t('authorVlm.defaultSite')"
              :disabled="disabled"
              required
            />
            <div class="form-group">
              <div class="title-text title-text--multiselect">
                {{ $t('authorVlm.sites') }}
                <Tooltip
                  v-if="duplicateEntryInServers"
                  :title="$t('authorVlm.warningMessages.duplicateEntryInServers')"
                  class="tooltip-duplicate-entry"
                  color="#f39c12"
                />
              </div>
              <MultiSelect
                ref="authorSites"
                :value="author.siteIds ? author.siteIds.map(siteId => enabledSites.find(site => site.id === siteId)) : []"
                @input="(inputSites) => author.siteIds = inputSites ? inputSites.filter(site => site.id !== author.defaultSite).map(site => site.id) : []"
                :options="authorSites"
                label="title"
                track-by="id"
                id="author_editorInChiefSites"
                :disabled="disabled"
              />
            </div>
          </div>
          <div class="two-columns">
            <div class="form-group">
              <div class="title-text title-text--multiselect">
                {{ $t('authorVlm.editorSites') }}
              </div>
              <MultiSelect
                :value="author.editorSiteIds ? author.editorSiteIds.map(siteId => enabledSites.find(site => site.id === siteId)) : []"
                @input="(editorSites) => author.editorSiteIds = editorSites ? editorSites.map(site => site.id) : []"
                :options="enabledSites"
                label="title"
                track-by="id"
                id="author_editorSites"
                :disabled="disabled"
              />
            </div>
            <div class="form-group">
              <div class="title-text title-text--multiselect">
                {{ $t('authorVlm.editorInChiefSites') }}
              </div>
              <MultiSelect
                :value="author.editorInChiefSiteIds ? author.editorInChiefSiteIds.map(siteId => enabledSites.find(site => site.id === siteId)) : []"
                @input="(editorInChiefSites) => author.editorInChiefSiteIds = editorInChiefSites ? editorInChiefSites.map(site => site.id) : []"
                :options="enabledSites"
                label="title"
                track-by="id"
                id="author_editorInChiefSites"
                :disabled="disabled"
              />
            </div>
          </div>
          <div class="form-group">
            <Checkbox
              v-model="author.showCompleteProfile"
              id="author_showCompleteProfile"
              :label="$t('authorVlm.showCompleteProfile')"
              :disabled="disabled"
            />
          </div>
          <div class="form-group">
            <div class="title-text">
              {{ $t('authorVlm.briefProfile') }}
            </div>
            <RichTextEditor
              v-model="author.description"
              :other-options="richTextEditorConfig"
              id="briefProfile"
              :readonly="disabled"
            />
          </div>
          <div class="form-group">
            <div class="title-text">
              {{ $t('authorVlm.completeProfile') }}
            </div>
            <RichTextEditor
              v-model="author.completeProfile"
              :other-options="richTextEditorConfig"
              id="completeProfile"
              :readonly="disabled"
            />
          </div>
        </div>
        <div class="form-content__separator">
          <div class="form-content__separator__line"/>
        </div>
        <div class="form-content__right">
          <div class="two-columns">
            <Input
              v-model="author.customText"
              id="author_customText"
              :label="$t('authorVlm.customText')"
              :disabled="disabled"
            />
            <Select
              v-model="author.sex"
              :options="sexOptions"
              :label="$t('authorVlm.sex')"
              :disabled="disabled"
              id="author_sex"
            />
          </div>
          <div class="two-columns">
            <Input
              v-model.trim="author.phone"
              id="author_phone"
              :label="$t('authorVlm.phone')"
              :disabled="disabled"
            />
            <Input
              v-model.trim="author.email"
              id="author_email"
              :label="$t('authorVlm.email')"
              :disabled="disabled"
            />
          </div>
          <div class="two-columns">
            <Input
              v-model.trim="author.shortcut"
              id="author_shortcut"
              :label="$t('authorVlm.shortcut')"
              :disabled="disabled"
            />
            <Input
              v-model.trim="author.slug"
              @blur="$v.author.slug.$touch()"
              :error="$v.author.slug.$error"
              id="author_url"
              :label="$t('authorVlm.url')"
              :disabled="disabled"
              required
            />
          </div>
          <div class="two-columns">
            <Input
              v-model.trim="author.position"
              id="author_function"
              :label="$t('authorVlm.function')"
              :disabled="disabled"
            />
            <OtherFunctionSearchSelect
              v-model="author.otherFunctionName"
              @selected-item="otherFunctionChanged"
              :disabled="disabled"
              :allow-custom-items="false"
              :search-on-empty-query="true"
              id="author_otherFunction"
              :key="`author_otherFunction-${forceUpdateOtherFunction}`"
            />
          </div>
          <div class="two-columns">
            <Input
              v-model.trim="author.social.twitter"
              id="author_twitter"
              :label="$t('authorVlm.twitter')"
              :disabled="disabled"
            />
            <Input
              v-model.trim="author.social.linkedin"
              id="author_linkedin"
              :label="$t('authorVlm.linkedin')"
              :disabled="disabled"
            />
          </div>
          <div class="two-columns">
            <Input
              v-model.trim="author.social.facebook"
              id="author_facebook"
              :label="$t('authorVlm.facebook')"
              :disabled="disabled"
            />
            <Input
              v-model.trim="author.social.instagram"
              id="author_instagram"
              :label="$t('authorVlm.instagram')"
              :disabled="disabled"
            />
          </div>
          <Input
            v-model.trim="author.social.pinterest"
            id="author_pinterest"
            :label="$t('authorVlm.pinterest')"
            :disabled="disabled"
          />
          <div class="author-types">
            <Checkbox
              v-model="author.typeAuthor"
              id="author_typeAuthor"
              :label="$t('authorVlm.typeAuthor')"
              :disabled="disabled"
            />
            <Checkbox
              v-model="author.typeEditor"
              id="author_typeEditor"
              :label="$t('authorVlm.typeEditor')"
              :disabled="disabled"
            />
            <Checkbox
              v-model="author.typeSportEditor"
              id="author_typeSportEditor"
              :label="$t('authorVlm.typeSportEditor')"
              :disabled="disabled"
            />
            <Checkbox
              v-model="author.typeReaderReporter"
              id="author_typeReaderReporter"
              :label="$t('authorVlm.typeReaderReporter')"
              :disabled="disabled"
            />
            <Checkbox
              v-model="author.typePhotographer"
              id="author_typePhotographer"
              :label="$t('authorVlm.typePhotographer')"
              :disabled="disabled"
            />
            <Checkbox
              v-model="author.typeExternalAuthor"
              id="author_typeExternalAuthor"
              :label="$t('authorVlm.typeExternalAuthor')"
              :disabled="disabled"
            />
          </div>
          <div class="form-group">
            <Checkbox
              v-model="author.showContactForm"
              id="author_showContactForm"
              :label="$t('authorVlm.showContactForm')"
              :disabled="disabled"
            />
            <Checkbox
              v-model="author.hiddenOnPage"
              id="author_hiddenOnPage"
              :label="$t('authorVlm.hiddenOnPage')"
              :disabled="disabled"
            />
            <Checkbox
              v-model="author.disabled"
              id="author_disabled"
              :label="$t('authorVlm.disabled')"
              :disabled="disabled"
            />
          </div>
          <div class="form-group">
            <div class="title-text">
              {{ $t('authorVlm.authorImage') }}
            </div>
            <ModuleFormPhotoBox
              :image="authorImage"
              :disabled="disabled"
              :media-usage-type="mediaUsageType"
              box-height="200px"
              box-width="200px"
              @set-media="setImage"
              @remove-media="removeImage"
            />
          </div>
        </div>
      </div>
    </template>
  </ModuleForm>
</template>

<script>
import { maxLength, minLength, minValue, required } from 'vuelidate/lib/validators'
import AuthorTypes from '@/model/ValueObject/AuthorTypes'
import AuthorModelVlm from '@/model/AuthorModelVlm'
import TinyMceConfigVLMSettingsSecondary from '@/model/TinyMceConfigVLMSettingsSecondary'
import NotifyService from '@/services/NotifyService'
import PermissionService from '@/services/PermissionService'
import { MEDIA_USAGE_TYPE_AUTHOR } from '@/model/ValueObject/MediaUsageTypes'
import ModuleFormPhotoBox from '@/components/shared/ModuleFormPhotoBox'
import ModuleFormDeleteButton from '@/components/shared/ModuleFormDeleteButton'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import ModuleForm from '@/components/shared/ModuleForm'
import Input from '@/components/form/inputs/Input'
import Select from '@/components/form/select/Select'
import MultiSelect from '@/components/form/select/MultiSelect'
import Checkbox from '@/components/form/Checkbox'
import Tooltip from '@/components/tooltip/Tooltip'
import RichTextEditor from '@/components/form/RichTextEditor'
import TinyMceConfig from '@/services/tinymce/TinyMceConfig'
import SourceSearchSelect from '@/components/author/SourceSearchSelect'
import OtherFunctionSearchSelect from '@/components/author/OtherFunctionSearchSelect'
import Preloader from '@/components/preloader/Preloader'

export default {
  name: 'AuthorNewViewVlm',
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      dataLoaded: true,
      forceUpdateSource: 1,
      forceUpdateOtherFunction: 1,
      authorTypes: AuthorTypes,
      duplicateEntryInServers: false,
      author: this._.cloneDeep(AuthorModelVlm),
      richTextEditorConfig: {
        ...TinyMceConfig.getConfig(), ...TinyMceConfigVLMSettingsSecondary
      },
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SYSTEM_AUTHOR_PERMISSIONS,
      mediaUsageType: MEDIA_USAGE_TYPE_AUTHOR
    }
  },
  components: {
    OtherFunctionSearchSelect,
    SourceSearchSelect,
    ModuleFormPhotoBox,
    ModuleFormDeleteButton,
    ModuleFormButton,
    Input,
    ModuleForm,
    Select,
    MultiSelect,
    Checkbox,
    Tooltip,
    RichTextEditor,
    Preloader
  },
  computed: {
    authorImage () {
      return this.$store.getters['author/authorImage']
    },
    enabledSites () {
      return this.$store.getters['site/allEnabledSorted']()
    },
    authorSites () {
      return this.enabledSites.filter(site => site.id !== this.author.defaultSite)
    },
    users () {
      return this.$store.getters['user/all']
    },
    sexOptions () {
      return [
        { id: 'male', title: this.$t('authorVlm.sexMale') },
        { id: 'female', title: this.$t('authorVlm.sexFemale') }
      ]
    }
  },
  validations: {
    author: {
      source: {
        required
      },
      originName: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(250)
      },
      slug: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(250)
      },
      defaultSite: {
        required,
        minValue: minValue(1)
      }
    }
  },
  methods: {
    defaultSiteChanged () {
      this.$refs.authorSites.refresh()
    },
    sourceChanged (source) {
      this.author.source = source?.id ?? null
      this.author.customText = source?.customText ? source.customText : this.author.customText
      this.forceUpdateSource += 1
    },
    otherFunctionChanged (otherFunction) {
      this.author.otherFunction = otherFunction?.id ?? null
      this.forceUpdateOtherFunction += 1
    },
    async getAuthor () {
      try {
        this.loading = true
        await this.$store.dispatch('author/fetchOne', this.$route.params.id)
        this.author = this.$store.getters['author/detail']
        if (this.author.siteIds.includes(this.author.defaultSite)) {
          this.author.siteIds = this.author.siteIds.filter(site => site !== this.author.defaultSite)
          this.duplicateEntryInServers = true
        } else {
          this.duplicateEntryInServers = false
        }
        if (this.author.source) {
          const source = await this.$store.dispatch('source/fetchOne', this.author.source)
          this.author.sourceName = source.customText ? source.customText : source.name
        }
        if (this.author.otherFunction) {
          const otherFunction = await this.$store.dispatch('otherFunction/fetchOne', this.author.otherFunction)
          this.author.otherFunctionName = otherFunction.name
        }
      } catch (error) {
        console.error(error)
        NotifyService.setErrorMessage(error)
      } finally {
        this.dataLoaded = true
        this.loading = false
      }
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
      } else {
        this.loading = true
        this.author.image = null
        if (this.$store.getters['author/authorImage']) {
          this.author.image = this.$store.getters['author/authorImage'].id
        }
        const action = this.author.id ? 'update' : 'create'
        const successNotify = this.author.id ? 'record_was_updated' : 'record_was_created'
        await this.$store.dispatch(`author/${action}`, this.author)
          .then(async () => {
            this.author.id = this.$store.getters['author/detail'].id
            const error = this.$store.getters['author/error']
            if (!error) {
              NotifyService.setSuccessMessage(this.$t(`notify.${successNotify}`))
              if (this.$route.fullPath.includes('/edit')) {
                await this.getAuthor()
              } else {
                this.goToEdit()
              }
            } else {
              console.error(error)
              NotifyService.setErrorMessage(error)
            }
          })
          .catch(error => console.error(error))
          .finally(() => {
            this.loading = false
          })
      }
    },
    setImage (medias) {
      this.$store.commit('author/storeAuthorImage', medias[0])
    },
    removeImage () {
      this.$store.commit('author/storeAuthorImage', null)
    },
    goToEdit () {
      this.$router.push(`/author/${this.author.id}/edit`)
    }
  },
  created () {
    this.$store.commit('author/storeAuthorImage', null)
  }
}
</script>

<style scoped lang="scss">
.title-text {
  @include font(400 14px "Roboto");
  color: #8A96AC;
  &--multiselect {
    padding-top: rem(3px);
    padding-bottom: rem(4px);
  }
}
.two-columns {
  display: flex;
  gap: 2%;
  > * {
    width: 100%
  }
}
.author-types {
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.tooltip-duplicate-entry {
  margin-left: 0.2rem;
}
</style>
